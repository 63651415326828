import { createApp, provide, h } from 'vue';
import App from './App.vue';
import router from './router';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { apolloLink } from '@/composables/useConnString';

const cache = new InMemoryCache({
  addTypename: false,
});

const httpLink = createHttpLink({
  uri: apolloLink,
});

const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
});

createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },

  render: () => h(App),
})
  .use(router)
  .mount('#app');
