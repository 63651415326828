<template>
  <div>
    <Nav active="patterns" @add="addPattern" @save="save" :showSave="true" :isDirty="isDirty" :isSaving="isSaving" />
    <div class="table">
      <div class="table-header">
        <div class="table-header-item">Name</div>
        <div class="table-header-item">Image</div>
        <div class="table-header-item"></div>
        <div class="table-header-item"></div>
      </div>
      <div class="table-row" v-if="!isLoading.value" v-for="(pattern, index) in patterns">
        <div class="table-cell input-cell" :class="{ 'is-new': pattern.isNew }">
          <span>{{ pattern.name }}</span>
          <input type="text" v-model="pattern.name" @input="markDirty(pattern)" />
        </div>
        <div class="table-cell image-cell" v-if="!pattern.isNew">
          <input type="file" :id="`patternImage${pattern.patternid}`" @change="changeSelectedImage(pattern)" />
          <p class="action" @click="selectPatternImage(pattern.patternid)">{{ pattern.image ? 'Change Image' : 'Upload Image' }}</p>
          <div class="image-text" v-if="pattern.image || pattern.newImageText">
            {{ pattern.newImageText || pattern.image }}
            <img v-if="pattern.image" :src="`${connString}/files/${pattern.image}`" />
          </div>
        </div>
        <div class="table-cell" v-else></div>
        <div class="table-cell actions" @click="deletePattern(pattern.patternid, index)"><img src="../assets/trash.svg" /></div>
      </div>
      <div class="table-row" v-if="isLoading.value">
        <div class="table-cell">Loading...</div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from '../components/Nav.vue';
import usePatternsQuery from '../composables/usePatternsQuery';
import usePatternsMutate from '../composables/usePatternsMutate';
import { uploadFile } from '../composables/useFileMutate';
import { connString } from '../composables/useConnString';
export default {
  name: 'Patterns',
  components: {
    Nav,
  },
  setup() {
    const { patterns, refetchPatterns, isLoading } = usePatternsQuery;
    const { updatePatterns, deletePattern } = usePatternsMutate();

    return {
      patterns,
      refetchPatterns,
      isLoading,
      updatePatterns,
      deletePattern,
      uploadFile,
      connString,
    };
  },
  data() {
    return {
      isSaving: false,
    };
  },
  methods: {
    addPattern() {
      this.patterns.push({
        name: '',
        patternid: null,
        isNew: true,
      });
    },
    markDirty(pattern) {
      pattern.isDirty = true;
    },
    async save() {
      const invalidPattern = this.patterns.find((pattern) => !pattern.name);
      if (invalidPattern) {
        alert('All fields must be filled out before saving.');
        return;
      }
      this.isSaving = true;

      const newAndDirty = this.patterns.filter((pattern) => pattern.isNew || pattern.isDirty);
      newAndDirty.forEach((pattern) => {
        delete pattern.newImageText;
        delete pattern.image;
      });

      const success = await this.updatePatterns(newAndDirty);

      for (const pattern of newAndDirty) {
        const imageInput = document.getElementById(`patternImage${pattern.patternid}`);
        if (imageInput?.files?.length > 0) {
          await this.uploadFile(imageInput.files[0], 'pattern', pattern.patternid);
        }
      }

      if (success) {
        await this.refetchPatterns();
      }

      this.isSaving = false;
    },
    async deletePattern(patternid, index) {
      if (!confirm('Are you sure you want to delete this pattern?')) return;
      const pattern = this.patterns[index];

      if (pattern.isNew) {
        this.patterns.splice(index, 1);
      } else {
        const success = await this.deletePattern(pattern.patternid);
        if (success) {
          this.patterns.splice(index, 1);
        }
      }
    },
    selectPatternImage(patternid) {
      document.getElementById(`patternImage${patternid}`).click();
    },
    changeSelectedImage(pattern) {
      this.markDirty(pattern);
      const input = document.getElementById(`patternImage${pattern.patternid}`);
      pattern.newImageText = input.files[0]?.name;
    },
  },
  computed: {
    isDirty() {
      return this.patterns.some((pattern) => pattern.isDirty) || this.patterns.some((pattern) => pattern.isNew);
    },
  },
  mounted() {
    this.refetchPatterns();
  },
};
</script>

<style scoped lang="scss">
.table {
  & > div > div:nth-child(1) {
    width: 16%;
    min-width: 14rem;
  }

  & > div > div:nth-child(2) {
    flex: 1;
  }

  & > div > div:nth-child(3) {
    width: 4rem;
  }

  .image-cell {
    input {
      display: none;
    }

    .action {
      cursor: pointer;
      margin: 0;
      text-decoration: underline;
    }

    .image-text {
      margin-left: 0.5rem;
      position: relative;

      &:hover {
        text-decoration: underline;
        img {
          display: block;
        }
      }

      img {
        display: none;
        width: 10rem;
        height: auto;
        position: absolute;
        top: 1.5rem;
        left: 0;
        border: 2px solid black;
        box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
        z-index: 1000;
        background-color: white;
      }
    }
  }
}
</style>
