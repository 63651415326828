import { connString, key } from '@/composables/useConnString';

async function uploadFile(file, type, id) {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);
    formData.append('id', id);
    const response = await fetch(`${connString}/files/upload?key=${key}`, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Failed to upload file');
    }

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export { uploadFile };
