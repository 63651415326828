import gql from 'graphql-tag';
import { useQuery } from '@vue/apollo-composable';
import { reactive, ref } from 'vue';

const products = reactive([]);
const refetchProducts = ref(null);
const isLoading = ref(false);

const GET_PRODUCTS = gql`
  query GetProducts {
    getProducts {
      productid
      name
      type
      vehiclesafe
      image
      productsizes {
        productsizeid
        productid
        canbeborder
        dimensions
        length
        width
        name
        perlayer
        perpallet
        sqft
        weight
        sortorder
        image
        productsizesurfaces {
          productsizeid
          productsizesurfaceid
          surfaceid
          productsizesurfacecolors {
            productsizesurfacecolorid
            productsizesurfaceid
            colorid
          }
        }
      }
    }
  }
`;

const getProducts = function () {
  try {
    const { result, loading, error, refetch, onResult } = useQuery(GET_PRODUCTS);

    onResult(() => {
      if (!result.value?.getProducts) return;
      const productsCopy = structuredClone(result.value.getProducts);
      products.splice(0, products.length, ...productsCopy);
    });

    isLoading.value = loading;
    refetchProducts.value = refetch;
  } catch (err) {
    console.error(err);
  }
};

export default {
  getProducts,
  products,
  refetchProducts,
  isLoading,
};
