<template>
  <router-view />
</template>

<script>
import useSurfacesQuery from './composables/useSurfacesQuery';
import useColorsQuery from './composables/useColorsQuery';
import usePatternsQuery from './composables/usePatternsQuery';
import useProductsQuery from './composables/useProductsQuery';
import useProductPatternsQuery from './composables/useProductPatternsQuery';

export default {
  setup() {
    useSurfacesQuery.getSurfaces();
    useColorsQuery.getColors();
    usePatternsQuery.getPatterns();
    useProductsQuery.getProducts();
    useProductPatternsQuery.getProductPatterns();
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
#app {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  background-color: #fafafa;
}

button {
  border: none;
  background: #2a4b7c;
  border-radius: 5px;
  color: #fff;
  padding: 0.7rem 2rem;
  outline: none;
  font-size: 1.3rem;
  cursor: pointer;
  text-transform: uppercase;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}

.table {
  display: flex;
  flex-direction: column;
  background-color: #fff;

  .table-header {
    display: flex;
    border-bottom: 1px solid #eeeeee;

    .table-header-item {
      font-weight: 500;
      font-size: 1rem;
      padding: 1.2rem 1rem 0.4rem 1rem;
    }
  }

  .table-row {
    display: flex;
    border-bottom: 1px solid #eeeeee;

    .table-cell {
      font-size: 0.9rem;
      padding: 0.6rem 1rem;
      display: flex;
      align-items: center;
    }

    .actions {
      cursor: pointer;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .input-cell {
      input {
        display: none;
        padding: 0.35rem;
        border-radius: 4px;
        outline: none;
        border: 1px solid #e0e0e0;
        width: 100%;
        font-size: 0.95rem;
      }

      select {
        display: none;
        padding: 0.28rem 0.15rem;
        border-radius: 4px;
        outline: none;
        border: 1px solid #e0e0e0;
        width: 100%;
        font-size: 0.95rem;
      }
    }

    .input-cell:focus-within,
    .input-cell:hover,
    .is-new {
      margin-left: -0.4rem;
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      padding-right: 1.4rem;
      input,
      select {
        display: block;
      }

      span {
        display: none;
      }
    }
  }

  & > div > div:first-child {
    padding-left: 3.5rem !important;
  }
}

.primary-input {
  position: relative;
  box-sizing: border-box;
  input[type='text'],
  input[type='number'],
  select {
    font-size: 0.875rem;
    line-height: 1.5;
    color: rgb(0, 0, 0, 0.87);
    background-color: white;
    background-clip: padding-box;
    background-position: right 14px center;
    border: 1px solid #e0e0e0;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: none;
    box-sizing: border-box;
    width: 100%;

    &:disabled {
      color: rgb(0, 0, 0, 0.12);
    }
  }

  input[type='number'] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  select {
    padding: 0.9rem 2.5rem 0.65rem 0.7rem;

    &:focus {
      padding: calc(0.9rem - 1px) calc(2.5rem - 1px) calc(0.65rem - 1px) calc(0.7rem - 1px);
      border: solid 2px #2a4b7c;
    }
  }

  input[type='text'],
  input[type='number'] {
    padding: 0.9rem 0.7rem 0.65rem 0.7rem;
    &:focus:not(.no-outline) {
      padding: calc(0.9rem - 1px) calc(0.7rem - 1px) calc(0.65rem - 1px) calc(0.7rem - 1px);
      border: solid 2px #2a4b7c;
    }
  }

  select:focus + label,
  input[type='text']:focus + label,
  input[type='number']:focus + label {
    color: #2a4b7c;
  }

  label {
    position: absolute;
    top: -0.32rem;
    left: 0.55rem;
    font-size: 0.625rem;
    color: rgb(0, 0, 0, 0.6);
    background: white;
    padding: 0 0.3rem;
    border-radius: 0.625rem;
  }

  input[type='file'] {
    border: 1px solid #e0e0e0;
    border-radius: 0.25rem;
    padding: 0.9rem 0.7rem 0.65rem 0.7rem;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;

    &::file-selector-button {
      display: none;
    }
  }
}

.image-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 10rem;
  img {
    width: 100%;
    height: auto;
  }
}
</style>
