import gql from 'graphql-tag';
import { useQuery } from '@vue/apollo-composable';
import { reactive, ref } from 'vue';

const patterns = reactive([]);
const refetchPatterns = ref(null);
const isLoading = ref(false);

const GET_PATTERNS = gql`
  query GetPatterns {
    getPatterns {
      name
      patternid
      image
    }
  }
`;

const getPatterns = function () {
  try {
    const { result, loading, error, refetch, onResult } = useQuery(GET_PATTERNS);

    onResult(() => {
      if (!result.value?.getPatterns) return;
      const patternsCopy = structuredClone(result.value.getPatterns);
      patterns.splice(0, patterns.length, ...patternsCopy);
    });

    isLoading.value = loading;
    refetchPatterns.value = refetch;
  } catch (err) {
    console.error(err);
  }
};

export default {
  getPatterns,
  patterns,
  refetchPatterns,
  isLoading,
};
